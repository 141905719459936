<template>
  <div class="flex flex-col">
    <div
      class="flex h-full w-full items-center"
      :class="[
        border ? 'border-b border-gray-tertiary pb-1' : null,
        labelOnTop ? 'flex-col' : 'flex-row',
      ]"
    >
      <label v-if="label" class="mb-2 block font-bold text-gray-700" :for="id"
        >{{ label }}:</label
      >
      <slot name="icon"></slot>
      <slot name="prependText"></slot>
      <input
        :id="id"
        ref="input"
        v-bind="{ class: inputClass }"
        class="h-full w-full outline-none"
        :class="{ 'border-red-500': error }"
        :type="type"
        :value="modelValue"
        :readonly="readonly"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="onInput"
        @keydown="preventNonNumericInput"
      />
      <slot v-if="showLoading" name="loading">
        <LoadingSpinner width="20" height="20" />
      </slot>
    </div>
    <div v-if="error" class="mt-1 w-full text-xs text-red-500">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { nanoid } from "nanoid";
import LoadingSpinner from "~/components/icons/LoadingSpinner.vue";

defineOptions({
  name: "HhInput",
});

const props = defineProps({
  id: {
    type: String,
    default() {
      return `text-input-${nanoid()}`;
    },
  },
  type: {
    type: String,
    default: "text",
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  border: {
    type: Boolean,
    default: false,
  },
  labelOnTop: {
    type: Boolean,
    default: false,
  },
  showLoading: {
    type: Boolean,
    default: false,
  },
  placeholder: String,
  error: Boolean,
  errorMessage: String,
  label: String,
  modelValue: String,
  inputClass: String,
});

const emit = defineEmits(["update:modelValue"]);

function onInput(event: Event) {
  emit("update:modelValue", (event.target as HTMLInputElement).value);
}

function preventNonNumericInput(event: KeyboardEvent) {
  if (props.type !== "number") {
    return;
  }
  const keyCode = event.keyCode;
  // Allow numeric keys (0-9), backspace, and delete
  if (
    (keyCode >= 48 && keyCode <= 57) || // 0-9
    keyCode === 8 || // Backspace
    keyCode === 46 // Delete
  ) {
    return;
  }
  event.preventDefault();
}
</script>
<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
